/* eslint-disable @typescript-eslint/ban-types */
import type {
  ComponentPublicInstance,
  MethodOptions,
} from '@nuxtjs/composition-api'
import type { RawLocation, RouteConfig } from 'vue-router'
import type { ExtendedVue } from 'vue/types/vue'
import type {
  ProxyWatchers,
  VuexModule,
} from 'vuex-class-component/dist/interfaces'
import type { PSelectOption } from '~/components/PSelect/types'
import type { AccountAttributesStore } from '~/store/accountAttributes'
import type { BreadcrumbStore } from '~/store/breadcrumb'
import type { ConfigurationStore } from '~/store/configuration'
import { LoadingStore } from '~/store/loading'
import type { PermissionStore } from '~/store/permissions'
import { PortalConfigStore } from '~/store/portalConfig'
import type { RoleStore } from '~/store/roles'
import type { UserStore } from '~/store/users'
import type { NavigationStore } from '~/store/navigation'
import { CompanyStore } from '~/store/companies'
export * from '~/types/portal-config'

export type Vue2ComponentPublicInstance<T> = T extends ExtendedVue<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infer _Instance,
  infer Data,
  infer Methods,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infer _Computed,
  infer Props
>
  ? Methods extends MethodOptions
    ? ComponentPublicInstance<Props, {}, Data, {}, Methods>
    : unknown
  : unknown

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

export type OrderBy<T extends string> = Partial<Record<T, 'asc' | 'desc'>>

export interface PortalModulePermission {
  service: string
  group: number
  mask: number
}
export interface PortalModule {
  type?: 'module' | 'iframe' | 'link' | string | undefined
  src: string
  css?: string
  route?: Partial<Omit<RouteConfig, 'name' | 'path'>> &
    Required<Pick<RouteConfig, 'name' | 'path'>>
  ui: {
    icon: string
    label: string
  }
}

export type BreadcrumbItem = {
  location: RawLocation
  label: string
  external?: boolean
}

interface ConfigItem<T> {
  label: string
  children?: T
}
export type BreadcrumbConfigRecord = Record<
  string,
  string | ConfigItem<BreadcrumbConfigRecord>
>
export type BreadcrumbConfig = {
  '': ConfigItem<BreadcrumbConfigRecord>
}
export type BreadcrumbConfigItem =
  BreadcrumbConfigRecord[keyof BreadcrumbConfigRecord]

interface HasTrailingIcon {
  trailingIcon?: string
}

export interface PortalAccountAttributeBase<K = string> {
  type: string
  id: K
  label: string
  required?: boolean
}

export interface PortalAccountAttributeText<K = string>
  extends PortalAccountAttributeBase<K>,
    HasTrailingIcon {
  type: 'text'
}

export interface PortalAccountAttributeSelect<K = string>
  extends PortalAccountAttributeBase<K> {
  type: 'select'
  options: PSelectOption[]
}

export interface PortalAccountAttributeMultiselect<K = string>
  extends PortalAccountAttributeBase<K> {
  type: 'multiselect'
  trackBy?: string
  optionLabel: string
  translateOptionLabel?: boolean
  multiple?: boolean
  closeOnSelect?: boolean
  options: Record<string, unknown>[]
}

export interface PortalAccountAttributeDate<K = string>
  extends PortalAccountAttributeBase<K>,
    HasTrailingIcon {
  type: 'date'
}

export interface PortalAccountAttributeJobTitle<K = string>
  extends PortalAccountAttributeBase<K> {
  type: 'job-title'
}

export type PortalAccountAttribute<K = string> =
  | PortalAccountAttributeText<K>
  | PortalAccountAttributeMultiselect<K>
  | PortalAccountAttributeSelect<K>
  | PortalAccountAttributeDate<K>
  | PortalAccountAttributeJobTitle<K>

export type PortalAccountAttributeValue =
  | string
  | Record<string, unknown>
  | Array<string | Record<string, unknown>>

export type PortalAccountAttributesConfig<K = string> = Array<
  PortalAccountAttribute<K>
>

type ProxyType<T extends typeof VuexModule> = ProxyWatchers & InstanceType<T>
export interface Vxm {
  roles: ProxyType<typeof RoleStore>
  users: ProxyType<typeof UserStore>
  permissions: ProxyType<typeof PermissionStore>
  configuration: ProxyType<typeof ConfigurationStore>
  breadcrumb: ProxyType<typeof BreadcrumbStore>
  loading: ProxyType<typeof LoadingStore>
  accountAttributes: ProxyType<typeof AccountAttributesStore>
  portalConfig: ProxyType<typeof PortalConfigStore>
  navigation: ProxyType<typeof NavigationStore>
  companies: ProxyType<typeof CompanyStore>
}

export interface TableAction {
  icon: string
  label: string
  permissionFn: (index: number) => boolean
  fn: (index: number) => void
}
export type TableSortingKey<T extends string = string> = T | T[]
export type TableHeaderObject<K extends TableSortingKey = TableSortingKey> = {
  label: string
  sortingKey?: K
}
export type TableHeader = string | TableHeaderObject
export type TableOrderBy<K extends TableSortingKey = TableSortingKey> =
  | {
      key: K
      order: 'asc' | 'desc'
    }
  | undefined

export type PortalTrailingIcon =
  | {
      name: string
      fn: () => void | undefined
    }
  | string
  | undefined

export type PortalColor =
  | '--p-color-primary-100'
  | '--p-color-primary-90'
  | '--p-color-primary-80'
  | '--p-color-primary-70'
  | '--p-color-primary-60'
  | '--p-color-primary-50'
  | '--p-color-primary-40'
  | '--p-color-primary-30'
  | '--p-color-primary-20'
  | '--p-color-primary-10'
  | '--p-color-primary-05'
  | '--p-color-secondary-100'
  | '--p-color-secondary-90'
  | '--p-color-secondary-80'
  | '--p-color-secondary-70'
  | '--p-color-secondary-60'
  | '--p-color-secondary-50'
  | '--p-color-secondary-40'
  | '--p-color-secondary-30'
  | '--p-color-secondary-20'
  | '--p-color-secondary-10'
  | '--p-color-secondary-05'
  | '--p-color-light-100'
  | '--p-color-dark-100'
  | '--p-color-dark-90'
  | '--p-color-dark-80'
  | '--p-color-dark-70'
  | '--p-color-dark-60'
  | '--p-color-dark-50'
  | '--p-color-dark-40'
  | '--p-color-dark-30'
  | '--p-color-dark-20'
  | '--p-color-dark-10'
  | '--p-color-dark-05'
  | '--p-color-success-100'
  | '--p-color-success-140'
  | '--p-color-success-10'
  | '--p-color-warning-100'
  | '--p-color-warning-140'
  | '--p-color-warning-10'
  | '--p-color-danger-100'
  | '--p-color-danger-140'
  | '--p-color-danger-10'
  | '--p-color-info-100'
  | '--p-color-info-140'
  | '--p-color-info-10'

export class NotAllowedException extends Error {}
export class NotFoundException extends Error {}
export class NotSupportedException extends Error {}
export class IllegalArgumentException extends Error {}

export type EventWithTarget<T> = Event & { target: T }

import type { RawLocation, RouteConfig } from 'vue-router'

export type PortalRouteConfig = Partial<
  Omit<RouteConfig, 'name' | 'path' | 'component'>
> &
  Required<Pick<RouteConfig, 'name' | 'path'>>

export enum PortalPageType {
  DEFAULT,
  MODULE,
  IFRAME,
  NAVIGATION,
}

export type GeneratedPortalPageType =
  | PortalPageType.MODULE
  | PortalPageType.IFRAME
  | PortalPageType.NAVIGATION

export interface Permittable {
  permission?: number
}

export interface Routed {
  route: PortalRouteConfig
}

export interface Titled {
  title: string
}

export interface Labelled {
  label: string
}

export interface Translatable {
  translate?: boolean
}

export interface ModulePageConfig extends Routed, Permittable {
  rootUrl?: string
  endpoints?: Record<string, string>
  src: string
  css: string
  basePath?: string
  format?: 'umd' | 'esm'
  manifest?: string
}

export interface IframePageConfig extends Routed, Permittable, Titled {
  src: string
}

export interface DefaultPageConfig extends Permittable, Titled {
  permission: number
}

export interface NavigationItemConfig {
  target: RawLocation
  label: string
  ctaText?: string
  icon: string
  external?: boolean
  openInNewTab?: boolean
  permission?: number
}

export interface SubNavigationItemConfig extends NavigationItemConfig {
  primary?: boolean
  description?: string
  download?: boolean
}

export interface NavigationPageConfig
  extends Routed,
    Permittable,
    Titled,
    Translatable {
  description: string
  items: SubNavigationItemConfig[]
}

export type GeneratedPageConfigTypes =
  | ModulePageConfig
  | IframePageConfig
  | NavigationPageConfig
export type PageConfigTypes = DefaultPageConfig | GeneratedPageConfigTypes

export interface DefaultPage extends DefaultPageConfig {
  type: PortalPageType.DEFAULT
}

export interface ModulePage extends ModulePageConfig {
  type: PortalPageType.MODULE
}

export interface IframePage extends IframePageConfig {
  type: PortalPageType.IFRAME
}

export interface NavigationPage extends NavigationPageConfig {
  type: PortalPageType.NAVIGATION
}

export type PortalPage = DefaultPage | ModulePage | IframePage | NavigationPage
export type PortalGeneratedPage = ModulePage | IframePage | NavigationPage

export interface Locale {
  key: string // ISO-639-3, ie. "deu" or "eng"
  countryAndLocaleCodes: string // (ISO-639-1)-(ISO 3166-1), ie. "de-DE" or "en-US"
  localeCode: string // ISO-639-1, ie. "de" or "en"
}

export type LocaleConfig =
  | {
      locales: Locale[]
    }
  | string[]

export interface TrackingConfig {
  googleAnalytics?: string
  hubspot?: string
}

export interface LoginConfig {
  method?: string
}

export interface DocumentConfig {
  validateFileType: boolean
  allowedFileTypes: string[]
}

export interface PortalConfig {
  title?: string
  fuchsbau?: {
    id: string
    name: string
  }
  registrationContact?: string
  locales?: LocaleConfig
  tracking?: TrackingConfig
  login?: LoginConfig
  documents?: Partial<DocumentConfig>
  modules: ModulePageConfig[]
  iframes: IframePageConfig[]
  navigationPages: NavigationPageConfig[]
  pages: Record<string, DefaultPageConfig>
  navigation: NavigationItemConfig[]
}

export interface BasePortalConfig {
  tracking: TrackingConfig
  documents: DocumentConfig
  pages: Record<string, DefaultPageConfig>
}
